import styled from "styled-components";
import { colorAliases } from "../style/design-system";

export const OuterContainer = styled.div`
  width: 100vw;
  background: ${(props) => props.backGroundColor || `white`};
  color: ${(props) => props.color || colorAliases.textColor};
`;

export default OuterContainer;
