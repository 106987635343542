export const MOBILEBREAKPOINT = `768px`;

export const fontSizes = {
  xs: `12px`,
  sm: `14px`,
  md: `16px`,
  lg: `18px`,
  xl: `20px`,
  "2xl": `24px`,
  "3xl": `30px`,
  "4xl": `36px`,
  logo: `40px`,
  "5xl": `48px`,
  "6xl": `60px`,
  "7xl": `72px`,
};

export const sizes = {
  xxs: `4px`,
  xs: `8px`,
  sm: `12px`,
  md: `16px`,
  lg: `24px`,
  xl: `32px`,
  "2xl": `48px`,
  "3xl": `64px`,
  "4xl": `96px`,
  "5xl": `128px`,
  "6xl": `192px`,
  "7xl": `256px`,
  "8xl": `384px`,
  "9xl": `512px`,
  "10xl": `640px`,
  "11xl": `768px`,
  siteWidth: `1200px`,
};

export const appPalette = {
  charcoal: "#3e3f42",
  gray: "#adb3bf",
  darkGray: "#637281",
  lighterGray: "#fbfbfd",
  lightGray: "#edf0f5",
  darkBlue: "#436e94",
  lighterBlue: "#f7feff",
  lightBlue: "#f0f8ff",
  teal: "#7cbabf",
  darkTeal: "#2b8d96",
  yellow: "#fdb620",
  red: "#d92d2d",
  darkRed: "#af2727",
  lightRed: "#f8645c",
  sand: "#f7f8f4",
  white: "#fff",
  offwhite: "#f0f4f8",
  mint: "#def3e7",
  darkMint: "#025158",
};

export const colors = {
  gray: {
    50: `#F7F7F7`,
    75: `#E1E1E18C`,
    100: `#E1E1E1`,
    200: `#CFCFCF`,
    300: `#B1B1B1`,
    400: `#9E9E9E`,
    500: `#7E7E7E`,
    600: `#626262`,
    700: `#515151`,
    800: `#3B3B3B`,
    900: `#222222`,
  },
};

export const colorAliases = {
  turqoise: appPalette.darkTeal,
  turqoiseLight: appPalette.teal,
  beige: `rgb(247, 248, 243)`,
  beigeSlightlyLighter: `#f9faf5`,
  beigeExtraLight: `#fdfdfc`,
  red: `rgb(248, 100, 92)`,
  lightText: `rgb(232, 233, 233)`,
  green: `rgb(37, 174, 136)`,
  textColor: colors["gray"]["700"],
  grayText: `#A2A7B6`,
};

export const borders = {
  regular: `1px solid ${colors["gray"]["200"]}`,
};

export const borderRadius = {
  sm: `.125rem`,
  md: `.25rem`,
  lg: `.5rem`,
  full: `9999px`,
};

export const boxShadow = {
  sm: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  md: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  "md-extended-top": `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 -1px 6px -3px rgba(0,0,0,.2)`,
  lg: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  xl: `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
  "2xl": `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
  inputShadow: `0 1px 3px 0 rgba(0, 0, 0, .1)`,
};
