import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query SITE_METADATA_QUERY {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //         }
  //       }
  //     }
  //   `
  // ) something is wrong with this.
  return {
    title: "dflow",
    description: "dflow, effektive løsninger for dokumenter og signeringer.",
    siteUrl: `https://dflow.com`,
  };
};

export default useSiteMetadata;
